import React from 'react'
import Ringproduct from "../images/ring-p.jpg";
import Nosepin from "../images/nosepin.jpg";

import { Link } from "react-router-dom";
import Earringgold from "../images/Earring_gold.jpg";
import locket from "../images/Locket.jpg";
import Chokergold from "../images/Choker_gold.jpg";
import pendants from "../images/pendants.jpg";
import chain from "../images/chain.jpg";
import Puthichik from "../images/puthi_chik.jpg";



function Product() {
    return (
        <div>

            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='ring'>
                                        <div className="card shadow">
                                            <img src={Ringproduct} className="card-img-top" alt="Ringproduct" />
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "grey" }} className="card-title mb-1">Ring</h4>
                                                    <a href='ring'>
                                                        <div className="btn-grad12" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='nosepin'>
                                        <div className="card shadow">
                                            <img src={Nosepin} className="card-img-top " alt="Nosepin" />
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "grey" }} className="card-title mb-1">Nosepin</h4>

                                                    <a href="nosepin" className="btn-grad12"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Earring'>
                                        <div className="card shadow">
                                            <img src={Earringgold} className="card-img-top" alt="Earringgold" />
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "grey" }} className="card-title mb-1">Earring</h4>
                                                    <a href="#" className="btn-grad12"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Locket'>
                                        <div className="card shadow">
                                            <img src={locket} className="card-img-top" alt="locket" />
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "grey" }} className="card-title mb-1">Locket</h4>
                                                    <a href='Locket' className="btn-grad12"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='choker'>
                                        <div className="card shadow">
                                            <img src={Chokergold} className="card-img-top" alt="chokergold" />
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "grey" }} className="card-title mb-1">Choker</h4>

                                                    <a href='choker' className="btn-grad12" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </a>


                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Pendants'>
                                        <div className="card shadow">
                                            <img src={pendants} className="card-img-top " alt="pendants" />
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "grey" }} className="card-title mb-1">Pendants</h4>

                                                    <a href='Pendants' className="btn-grad12"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='chain'>
                                        <div className="card shadow">
                                            <img src={chain} className="card-img-top" alt="chain" />
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "grey" }} className="card-title mb-1">Chain</h4>
                                                    <a href='chain' className="btn-grad12"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Puthichik'>
                                        <div className="card shadow">
                                            <img src={Puthichik} className="card-img-top" alt="Puthichik" />
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "grey" }} className="card-title mb-1">Puthi Chik</h4>
                                                    <a href='Puthichik' className="btn-grad12"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product