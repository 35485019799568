
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contact from './components/pages/Contact';
import Ring from './components/pages/Ring';
import Nosepin from './components/pages/Nosepin';
import Goldpage from './components/pages/Goldpage';
import Earring from './components/pages/Earring';
import Locket from './components/pages/Locket';
import Choker from './components/pages/Choker';
import Pendants from './components/pages/Pendants';
import Chain from './components/pages/Chain';
import Puthichik from './components/pages/Puthichik';
import Silverpage from './components/pages/Silverpage';

import Gpage from './components/pages/Gpage';



function App() {
  return (
    <Router>

      <div>
        <Routes>


          <Route axact path="/" element={<Home />} />
          <Route axact path="/contact-us" element={<Contact />} />
          <Route axact path="/ring" element={<Ring />} />
          <Route axact path="/nosepin" element={<Nosepin />} />
          <Route axact path="/Goldpage" element={<Goldpage />} />
          <Route axact path="/Earring" element={<Earring />} />
          <Route axact path="/Locket" element={<Locket/>} />
          <Route axact path="/choker" element={<Choker/>} />
          <Route axact path="/Pendants" element={<Pendants/>}/>
          <Route axact path="/chain" element={<Chain/>}/>
          <Route axact path="/Puthichik" element={<Puthichik/>}/>
          <Route axact path="/Silver-page" element={<Silverpage/>}/>
          <Route axact path="/rate-today" element={<Gpage/>}/>
        


        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
