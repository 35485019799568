import React from 'react'
import Navbar from '../inc/Navbar'
import GoldProduct from "../pages/GoldProduct";
import {Link} from "react-router-dom";


function Goldpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='ring'>
                <div className='album py-1'>
                    <div className="container mt-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li  className="breadcrumb-item"><Link style={{color:"darkBlue"}} to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Gold Page </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
<br/>
        <GoldProduct/>
        <br/>
        <br/>
    </div>
  )
}

export default Goldpage