import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import { db } from '../../firebase'


import ReactWhatsapp from "react-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import Navbar from '../inc/Navbar';


import {
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
    FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

    LinkedinShareButton,
    LinkedinIcon,
    TwitterIcon,
} from "react-share";

function Puthichik() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(68);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [locket, setLocket] = useState([])
    const navigate = useNavigate()

    const locketCollectionRef = collection(db, "PUTHICHIK-GOLD");
    useEffect(() => {

        const getLocket = async () => {
            const data = await getDocs(locketCollectionRef);
            setLocket(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getLocket()
    }, [])
    return (

        <>
            <Navbar />
            <br />
            <ScrollToTop smooth top="100" color="maroon"/>
            <br />
            <br />
            <br />
            <br />

            <div className='topjewellersdhubulia'>
                <div className='album py-1'>
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Puthi Chick </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="album py-3">

                <div className="container">

                    <div className="row">

                        <div className="col-md-6">

                            <div className="card-body">
                                <input type="text" className="form-control" placeholder="Search" name="location"
                                    onChange={(e) => {
                                        setsearchTerm(e.target.value);
                                    }}
                                />
                                <div className="d-flex justify-content-between align-items-center">




                                </div>


                            </div>


                        </div>

                    </div>

                </div>
            </div>
            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        {locket.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((maajewellers) => {
                            return (
                                <div className="col-md-4 mt-8" >
                                    <div className="card mb-4 ">
                                        <img className="card-img-top" src={maajewellers.img} alt={maajewellers.name} />
                                        <div className="card-body">
                                            <h6 className="card-text"> <b>{maajewellers.name}</b></h6>
                                            <p className="card-text mb-2">{maajewellers.des}</p>
                                            <div className="d-flex justify-content-between align-items-center mb-1">
                                                <a href='tel: 9749208548' className="dhubuliabutton mb-3">Call Book</a>
                                                <ReactWhatsapp number="+91 9749208548" className="dhubuliabutton mb-3" message="I want to buy this shirt, contact me"> Whatsapp Book</ReactWhatsapp>
                                            </div>
                                            <button type="button" className="buttonshare me-1">
                                                Share  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                                </svg>
                                            </button>  < FacebookShareButton url="https://www.maajewellersdhubulia.com/ring" className="me-2" quote={"maajewellers"} hastag={"#maajewellers"}
                                            >
                                                <FacebookIcon size={30} round={true} />
                                            </FacebookShareButton>

                                            <  WhatsappShareButton url="https://www.maajewellersdhubulia.com/ring" className="me-2" quote={"maajewellers"} hastag={"#maajewellers"}
                                            >
                                                <WhatsappIcon size={30} round={true} />
                                            </ WhatsappShareButton>

                                            <  TwitterShareButton url="https://www.maajewellersdhubulia.com/ring" className="me-2" quote={"maajewellers"} hastag={"#maajewellers"}
                                            >
                                                <TwitterIcon size={30} round={true} />
                                            </ TwitterShareButton>

                                            <  LinkedinShareButton url="https://www.maajewellersdhubulia.com/ring" className="me-2" quote={"maajewellers"} hastag={"#maajewellers"}
                                            >
                                                <  LinkedinIcon size={30} round={true} />
                                            </ LinkedinShareButton>

                                            <  EmailShareButton url="https://www.maajewellersdhubulia.com/ring" quote={"maajewellers"} hastag={"#maajewellers"}
                                            >
                                                <  EmailIcon size={30} round={true} />
                                            </ EmailShareButton>

                                            <div className="d-flex justify-content-between align-items-center">

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>



                </div>
            </div>

        </>

    );
}

export default Puthichik;



