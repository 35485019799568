
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyBP4G09fxxeRNEYrfy3xcK2szf0sqfuIEA",
  authDomain: "maa-jewellers-dhubulia.firebaseapp.com",
  projectId: "maa-jewellers-dhubulia",
  storageBucket: "maa-jewellers-dhubulia.appspot.com",
  messagingSenderId: "111943532830",
  appId: "1:111943532830:web:92add90f2800bfb6f8490f",
  measurementId: "G-B8G43VEWVB"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;