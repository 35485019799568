import React from 'react'
import Navbar from '../inc/Navbar'
import GoldRate from './Goldrate'


function Gpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='ring'>
        <div className="album py-2">
<div className="container ">
            <center>
        <h3>Check Today's Gold Rate at MAA Jewellers Stores</h3>
        </center>
        </div>

        </div>
        </div>

    
        <div className='GOLDRATE'>
            <br/>
          

    
        <GoldRate/>
        <br/>
        </div>
    </div>
  )
}

export default Gpage