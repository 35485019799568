import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'


function Goldrate() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(9);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [goldratetodaynews, setGoldratetodaynews] = useState([])
    const navigate = useNavigate()

    const goldratetodaynewsCollectionRef = collection(db, "GOLD-RATE");
    useEffect(() => {

        const getgoldratetodaynews = async () => {
            const data = await getDocs(goldratetodaynewsCollectionRef);
            setGoldratetodaynews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getgoldratetodaynews()
    }, [])
    return (

        <>



            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {goldratetodaynews.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            }
                            {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((maajewellers) => {
                            return (

                                <div className="col-md-12 mt-2">

                                    <div className="card shadow border-primary" >
                                        <button className='buttongold'>সোনা ও রূপোর দর
                                       </button>
                                        <div className="card-body">
                                            <center>
                                                <h5 style={{ color: "maroon" }} className="card-title mt-2 mb-3">{maajewellers.date}</h5>
                                            </center>

                                            <div className="d-flex justify-content-between align-items-between mb-0">
                                                <h6 style={{ color: "darkBlue" }} className="card-text"> হলমার্ক সোনার গহনা <br /> (৯১৬/২২ ক্যাঃ ১০ গ্রাম) </h6>
                                                <h6 style={{color:"maroon"}} className='mt-4'><b>{maajewellers.rate22} </b></h6>
                                            </div>
                                            <hr />
                                            <div className="d-flex justify-content-between align-items-between mb-0">
                                                <h6 style={{ color: "darkBlue" }} className="card-text"> রূপোর  (প্রতি কেজি)  </h6>
                                                <h6 style={{color:"maroon"}} className='mt-0'><b>{maajewellers.ratesilver}</b> </h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            )
                        })}
                    </div>
                </div>
            </div >

        </>

    );
}

export default Goldrate;



