import React from 'react'
import Navbar from '../inc/Navbar'
import Silver from './Silver'
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";


function Silverpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="Darkblue"/>
        <div className='topjewellersdhubulia'>
                <div className='album py-1'>
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Silver</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        
        <Silver/>
    </div>
  )
}

export default Silverpage