import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import GoldProduct from './GoldProduct'
import "./Home.css";
import showroommaajewellers from "../images/maa jewellers-dhubulia.jpg"
import Googlemap from './Googlemap';
import ScrollToTop from "react-scroll-to-top";
import Silver from './Silver';
import Goldrate from './Goldrate';
import { FacebookEmbed } from 'react-social-media-embed';
function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
              <Goldrate />
            </div>
            <div className="col-md-8 mt-3">
              <Slider />
            </div>
          </div>
        </div>
      </div>
      <br />
      <ScrollToTop smooth top="100" color="goldenrod" />
      <div className='backm'>
        <div className="album py-2">
          <div className="container mt-2">
            <marquee><h4 style={{ color: "darkBlue" }} >Welcome to Maa Jewellers Dhubulia</h4></marquee>
          </div>
        </div>
      </div>
      <br />
      <div className="album py-2">
        <div className="container ">
          <h1 style={{ color: "goldenrod" }}><b style={{ color: "grey" }} >Explore </b> The New Arrivals</h1>
        </div>
      </div>
      <GoldProduct />
      <br />


      <div className='bhubuliajewellers'>
        <div className="album py-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mt-3 mb-3">
                <center>

                  <h4 className="card-text mb-1">Your idea, Our design </h4>
                  <a href='tel: +91 9749208548'>
                    <h5 style={{ color: "maroon" }} className="card-text">+91 97492 08548 </h5>
                  </a>
                  <p className="card-text">  Any Questions? </p>
                </center>

              </div>
              <div className="col-md-6 mt-3 mb-3">
                <center>
                  <h3 className="card-text">GOLD RATE</h3>
                  <p className="card-text mb-1"><small>One best rate acrossIndia!</small></p>
                  <a href='rate-today'>
                    <h5 style={{ color: "maroon" }} className="card-text">View Gold Rate</h5>

                  </a>
                </center>


              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      

      <br />
      <div className="album py-2">
        <div className="container ">
          <h1 style={{ color: "grey" }}>Shop by <b style={{ color: "goldenrod" }}>Your Preference</b></h1>
          <h6>All type silver products available at Maa Jewellers</h6>
        </div>
      </div>
      <Silver />
      <br />
      <div className="album py-2">
        <div className="container ">
          <div className='radius'>
            <img src={showroommaajewellers} className="d-block w-100" alt="maa jewellers" />
          </div>
        </div>
      </div>
      <br />
      <br />
      <Googlemap />
      <br />
      <br />
    </div>
  )
}

export default Home