import React from 'react'

function Googlemap() {
  return (
    <div>
      <div className="album py-1">

        <div className="container">

          <div className="row">
            <iframe className="iframe-fluid mb-4" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=MAA%20JEWELLERS,%20Belpukur%20Rd,%20Dhubulia,%20West%20Bengal%20741139%20+(MAA%20JEWELLERS,%20Belpukur%20Rd,%20Dhubulia,%20West%20Bengal%20741139)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
            <iframe src="https://www.google.com/maps/embed?pb=!4v1721891823654!6m8!1m7!1sPvysYvPTH-7L0eMDUOY5_Q!2m2!1d23.48064139758038!2d88.44788043470807!3f350.3794363317839!4f-1.8920816740810835!5f2.7445727709828662" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Googlemap